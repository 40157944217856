<script>
import AuthorNew from './AuthorNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'AuthorEditView',
  extends: AuthorNew,
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.author.image = null
        if (this.$store.getters['author/authorImage']) {
          this.author.image = this.$store.getters['author/authorImage'].id
        }
        this.$store.dispatch('author/update', this.author)
          .then(() => {
            this.author = this.$store.getters['author/detail']
            if (this.$store.getters['author/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
            } else {
              NotifyService.setErrorMessage(this.$store.getters['author/error'])
            }
          })
          .catch(error => console.log(error))
      }
    }
  },
  created () {
    this.getAuthor()
  }
}
</script>
