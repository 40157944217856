<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deleteAuthor" />
              <app-button-close route-name="author_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <input type="hidden" v-model="author.id">
                    <app-input
                      v-model="author.name"
                      @blur="$v.author.name.$touch()"
                      :error="$v.author.name.$error"
                      id="author_name"
                      label="Name">
                    </app-input>
                    <label>User</label>
                    <app-multi-select
                      v-model="authorUser"
                      :multiple="false"
                      :close-on-select="true"
                      :options="users"
                      label="username"
                      track-by="id"
                      id="author_user"
                    >
                    </app-multi-select>
                    <app-input
                      v-model="author.originName"
                      @blur="$v.author.originName.$touch()"
                      :error="$v.author.originName.$error"
                      id="author_originName"
                      label="Origin name (name for sorting)"
                    >
                    </app-input>
                    <app-input
                      v-model.trim="author.slug"
                      @blur="$v.author.slug.$touch()"
                      :error="$v.author.slug.$error"
                      id="author_slug"
                      label="Slug"
                    >
                    </app-input>
                    <app-input
                      v-model="author.position"
                      id="author_name"
                      label="Position"
                    >
                    </app-input>
                    <app-textarea
                      v-model="author.description"
                      id="author_description"
                      label="Description"
                    >
                    </app-textarea>
                    <app-input
                      v-model="author.email"
                      id="author_email"
                      label="Email"
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <app-select
                      v-model="author.type"
                      :options="authorTypes"
                      id="author_type"
                      label="Type">
                    </app-select>
                    <app-select
                      v-model="author.defaultSite"
                      @blur="$v.author.defaultSite.$touch()"
                      :error="$v.author.defaultSite.$error"
                      :options="sites"
                      id="author_defaultSite"
                      label="Default site">
                    </app-select>
                    <div class="row">
                      <div class="col-lg-6">
                        <app-input
                          v-model="author.social.facebook"
                          id="author_social_facebook"
                          label="Facebook"
                        >
                        </app-input>
                      </div>
                      <div class="col-lg-6">
                        <app-input
                          v-model="author.social.google"
                          id="author_social_google"
                          label="Google plus"
                        >
                        </app-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <app-input
                          v-model="author.social.twitter"
                          id="author_social_twitter"
                          label="Twitter"
                        >
                        </app-input>
                      </div>
                      <div class="col-lg-6">
                        <app-input
                          v-model="author.social.instagram"
                          id="author_social_instagram"
                          label="Instagram"
                        >
                        </app-input>
                      </div>
                    </div>
                    <div class="form-group">
                      <app-checkbox
                        v-model="author.hiddenOnPage"
                        id="author_hiddenOnPage"
                        label="Hidden on page"
                      >
                      </app-checkbox>
                      <app-checkbox
                        v-model="author.disabled"
                        id="author_disabled"
                        label="Disabled"
                      >
                      </app-checkbox>
                      <app-checkbox
                        v-model="author.detailPage"
                        id="author_detailPage"
                        label="Detail page"
                      >
                      </app-checkbox>
                    </div>
                    <div class="form-group">
                      <label>Image</label><br>
                      <app-media-select-button
                        :media-usage-type="mediaUsageType"
                        @set-media="setImage"
                      >
                      </app-media-select-button>
                      <app-media-upload-button
                        :media-usage-type="mediaUsageType"
                        @set-media="setImage"
                      >
                      </app-media-upload-button>
                    </div>
                    <app-media-editable
                      v-if="authorImage"
                      :media="authorImage"
                      :width="360"
                      :height="202"
                      :media-usage-type="mediaUsageType"
                      @set-media="setImage"
                      @remove-media="removeImage"
                    >
                    </app-media-editable>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Input from '../../components/form/inputs/Input'
import Textarea from '../../components/form/Textarea'
import Select from '../../components/form/select/Select'
import Checkbox from '../../components/form/Checkbox'
import MultiSelect from '../../components/form/select/MultiSelect'
import { maxLength, minLength, minValue, required } from 'vuelidate/lib/validators'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import AuthorTypes from '../../model/ValueObject/AuthorTypes'
import MediaSelectButton from '../../components/shared/MediaSelectButton'
import MediaUploadButton from '../../components/shared/MediaUploadButton'
import AuthorModel from '../../model/AuthorModel'
import MediaEditable from '../../components/shared/MediaEditable'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'
import { MEDIA_USAGE_TYPE_AUTHOR } from '@/model/ValueObject/MediaUsageTypes'

export default {
  name: 'AuthorNewView',
  data () {
    return {
      dataLoaded: false,
      authorTypes: AuthorTypes,
      author: this._.cloneDeep(AuthorModel),
      authorUser: null,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_AUTHOR_PERMISSIONS,
      mediaUsageType: MEDIA_USAGE_TYPE_AUTHOR
    }
  },
  computed: {
    isDeleteButtonVisible () {
      return this.author?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    authorImage () {
      return this.$store.getters['author/authorImage']
    },
    sites () {
      return this.$store.getters['site/all']
    },
    users () {
      return this.$store.getters['user/all']
    }
  },
  validations: {
    author: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      originName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      slug: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      defaultSite: {
        required,
        minValue: minValue(1)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appTextarea: Textarea,
    appSelect: Select,
    appCheckbox: Checkbox,
    appMultiSelect: MultiSelect,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaEditable: MediaEditable
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getAuthor () {
      this.$store.dispatch('author/fetchOne', this.$route.params.id)
        .then(() => {
          this.author = this.$store.getters['author/detail']
          if (this.author.user) {
            this.authorUser = [this.$store.getters['user/userById'](this.author.user)]
          }
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.author.image = null
        if (this.$store.getters['author/authorImage']) {
          this.author.image = this.$store.getters['author/authorImage'].id
        }
        this.$store.dispatch('author/create', this.author)
          .then(() => {
            this.author = this.$store.getters['author/detail']
            if (this.$store.getters['author/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.$router.push('/author/' + this.author.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['author/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deleteAuthor () {
      this.$store.dispatch('author/deleteRecord', this.author)
        .then(() => {
          if (this.$store.getters['author/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/author')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['author/error'])
          }
        })
        .catch(error => console.log(error))
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('author/storeAuthorImage', media)
      })
    },
    removeImage () {
      this.$store.commit('author/storeAuthorImage', null)
    }
  },
  created () {
    this.$store.commit('author/storeAuthorImage', null)
  },
  watch: {
    authorUser () {
      if (this.authorUser) {
        this.author.user = this.authorUser.id

        return
      }

      this.author.user = null
    }
  }
}
</script>
